import API from '../API';


export function show(page) {
    return API.get('/lookup/power-play/show?page=' + page);
}

export function store(data) {
    return API.post('/lookup/power-play/store', data);
}

export function activeInActive(data) {
    return API.post('/lookup/power-play/active-inactive', data);
}

export function update(data) {
    return API.post('/lookup/power-play/update', data);
}

export function destroy(data) {
    return API.post('/lookup/power-play/destroy', data);
}


export function search(data) {
    return API.post('/lookup/power-play/search', data);
}

