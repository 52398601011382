<template>
  <div>
    <div class="card">
      <div class="card-header">

        <h4 data-toggle="collapse" data-target="#panel-body-1" aria-expanded="true">
          Entities/ Countries <i class="fa fa-angle-down"/>
        </h4>
        <button class="btn btn-info btn-right" data-toggle="modal" data-target="#newEntity">
          <i class="fa fa-plus"></i> Add
        </button>


      </div>
      <div id="accordion">
        <div class="accordion">

          <div class="accordion-body collapse" id="panel-body-1" data-parent="#accordion" style="">

            <div class="card-body">
              <div class="row">
                <div class="col-12 col-md-6 offset-md-6">
                  <div class="form-group">
                    <input type="text" name="seek" placeholder="Search" class="form-control form-control-sm"
                           v-model="seek"
                           @keypress.enter="search">
                  </div>
                </div>
              </div>

              <div class="table-responsive">
                <table class="table table-bordered table-md">
                  <tbody>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Status</th>
                    <th class="text-center">Action</th>
                  </tr>
                  <tr v-for="(entity, index) in entities.data" :key="index">
                    <td>{{ entity.id }}</td>
                    <td>{{ entity.abbreviation }}</td>

                    <td>
                      <div class="badge badge-success" @click="activeInactive(entity.id, entity.active)"
                           :class="{'badge-success': entity.active === 1, 'badge-danger': entity.active !== 1}">
                        <span v-if="entity.active === 1">Active</span>
                        <span v-else>Inactive</span>
                      </div>
                    </td>
                    <td class="text-center">
                      <a href="#" class="btn btn-primary btn-sm" data-toggle="modal" data-target="#newEntity"
                         @click.prevent="set(index)">Detail</a>
                      <a href="#" class="btn btn-danger btn-sm ml-1" @click.prevent="destroy(entity.id)">Remove</a>
                    </td>
                  </tr>

                  </tbody>
                </table>
              </div>
            </div>
            <div class="card-footer text-right">
              Showing {{ entities.to }} from {{ entities.total }} Records
              <nav class="d-inline-block">
                <ul class="pagination mb-0">
                  <li class="page-item" :class="{'disabled': entities.current_page === 1}">
                    <a class="page-link" href="#" tabindex="-1" @click.prevent="show(entities.current_page -1)">
                      <i class="fas fa-chevron-left"></i>
                    </a>
                  </li>

                  <li class="page-item" :class="{'disabled': entities.current_page === entities.last_page}">
                    <a class="page-link" href="#" @click.prevent="show(entities.current_page + 1)"><i
                        class="fas fa-chevron-right"></i></a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>


    </div>
    <!-- Modal with form -->
    <div class="modal fade" id="newEntity" tabindex="-1" role="dialog" aria-labelledby="formModal"
         aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="formModal">New Entity/ Country</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click.prevent="resetForm">
              <i class="fa fa fa-window-close"></i>
            </button>
          </div>

          <div class="modal-body">
            <form enctype="multipart/form-data" method="post" id="entityForm" @submit.prevent="newEntity">
              <div class="row">
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label for="name"> Name </label>

                    <input type="text" class="form-control" placeholder="Name" name="name" id="name"
                           v-model="entity.name">
                    <input type="hidden" name="entity_id" v-model="entity.id" v-if="entity.id">
                    <span class="text text-danger" v-if="validationErrors.name">{{ validationErrors.name[0] }}</span>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label for="abbreviation">Abbreviation</label>


                    <input type="text" class="form-control" placeholder="Abbreviation" name="abbreviation"
                           id="abbreviation" v-model="entity.abbreviation">
                    <span class="text text-danger"
                          v-if="validationErrors.abbreviation">{{ validationErrors.abbreviation[0] }}</span>

                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label for="logo">Logo</label>
                    <br>
                    <img :src="$store.state.Global.rootCDN + entity.logo" alt="" style="height: 70px;">
                    <input type="hidden" name="old_logo" v-model="entity.logo" v-if="entity.id">

                    <input type="file" class="form-control" name="logo"
                           id="logo">
                    <span class="text text-danger" v-if="validationErrors.logo">{{ validationErrors.logo[0] }}</span>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label for="flag">Flag</label>
                    <br>

                    <img :src="$store.state.Global.rootCDN + entity.flag" alt="" style="height: 70px;">
                    <input type="file" class="form-control" name="flag"
                           id="flag">
                    <input type="hidden" name="old_flag" v-model="entity.flag" v-if="entity.id">
                    <span class="text text-danger" v-if="validationErrors.flag">{{ validationErrors.flag[0] }}</span>


                  </div>
                </div>
                <div class="col-12 col-md-12">
                  <div class="form-group">
                    <label for="description">Description</label>


                    <textarea class="form-control" name="description" id="description"
                              v-model="entity.description"></textarea>
                    <span class="text text-danger"
                          v-if="validationErrors.description">{{ validationErrors.description[0] }}</span>

                  </div>
                </div>
              </div>

              <button type="submit" class="btn btn-primary m-t-15 waves-effect ml-2 float-right">Save</button>
              <button type="reset" class="btn btn-danger m-t-15 waves-effect float-right"
                      data-dismiss="modal" aria-label="Close"
                      @click="resetForm" v-if="entity.id"
              >
                Cancel
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- Vertically Center -->
  </div>


</template>
<script>
import {activeInActive, destroy, search, show, store, update} from "@/services/lookup/Entity";
import $ from 'jquery'
import {useToast} from "vue-toastification";

export default {
  data() {
    return {
      validationErrors: [],
      toast: useToast(),
      entities: [],
      entity: {},
      seek: ''
    }
  },

  methods: {
    setError(error) {

      this.toast.clear();

      if (error.response.status === 422) {


        this.validationErrors = error.response.data.errors;

        this.toast.error(error.response.data.message);

      } else {

        this.toast.error('Something went wrong, please contact webmaster.')
      }
    },
    show(page = 1) {
      show(page).then(response => {

        this.entities = response.data;
      }).catch(error => {
        this.setError(error);
      })
    },
    newEntity() {

      this.toast.info('processing...')
      let form = document.getElementById('entityForm')
      let data = new FormData(form);
      if (!this.entity.id) {
        store(data).then(() => {

          $('#newEntity').hide('hide');

          this.toast.clear();

          this.toast.success('New Entity Added')

          this.show();
          this.validationErrors = [];
          this.entity = {};

        }, error => {
          this.setError(error)
        });
      } else {
        update(data).then(() => {
          $('#newEntity').hide('hide');

          this.toast.clear();

          this.toast.success('Entity Updated')

          this.show(this.entities.current_page);

          this.entity = {};
          this.validationErrors = [];

        }, error => {
          this.setError(error);
        });
      }

    },

    activeInactive(id, active) {

      let data = {
        id: id,
        active: active
      }

      this.toast.info('processing...')

      activeInActive(data).then(() => {
        this.toast.clear();
        this.show(this.entities.current_page)
      });

    },
    set(index) {
      this.entity = this.entities.data[index];
    },

    resetForm() {
      $('#newEntity').hide('hide')
      this.entity = {};
    },

    destroy(id) {

      let data = {
        'entity_id': id,
      };

      this.$swal({

        title: 'Are you sure?',

        text: "You won't be able to revert this!",

        type: 'warning',

        showCancelButton: true,

        confirmButtonColor: '#d33',

        cancelButtonColor: '#3085d6',

        confirmButtonText: 'Yes, delete it!'

      }).then((result) => {

        if (result.value) {


          destroy(data).then(() => {


            this.$swal(
                'Deleted!',

                'Record has been deleted.',

                'success'
            );
            this.show(this.entities.current_page);

          });


        }

      });


    },

    search() {
      let data = {
        'seek': this.seek,
      };
      search(data).then(response => {


        this.entities.data = null;

        this.entities.data = response.data;

      });
    }
  },

  mounted() {
    this.show();

  }
}
</script>