import API from '../API';


export function show(page) {
    return API.get('/lookup/game-format/show?page=' + page);
}

export function store(data) {
    return API.post('/lookup/game-format/store', data);
}

export function activeInActive(data) {
    return API.post('/lookup/game-format/active-inactive', data);
}

export function update(data) {
    return API.post('/lookup/game-format/update', data);
}

export function destroy(data) {
    return API.post('/lookup/game-format/destroy', data);
}


export function search(data) {
    return API.post('/lookup/game-format/search', data);
}
