import API from '../API';


export function show(page) {
    return API.get('/lookup/official-types/show?page=' + page);
}

export function store(data) {
    return API.post('/lookup/official-types/store', data);
}

export function activeInActive(data) {
    return API.post('/lookup/official-types/active-inactive', data);
}

export function update(data) {
    return API.post('/lookup/official-types/update', data);
}

export function destroy(data) {
    return API.post('/lookup/official-types/destroy', data);
}


export function search(data) {
    return API.post('/lookup/official-types/search', data);
}
