<template>
  <div>
    <div class="card">
      <div class="card-header">

        <h4 data-toggle="collapse" data-target="#panel-body-6" aria-expanded="true">
          Delays <i class="fa fa-angle-down"/>
        </h4>
        <button class="btn btn-info btn-right" data-toggle="modal" data-target="#newDelay">
          <i class="fa fa-plus"></i> Add
        </button>


      </div>
      <div id="accordion">
        <div class="accordion">

          <div class="accordion-body collapse" id="panel-body-6" data-parent="#accordion" style="">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-md-6 offset-md-6">
                  <div class="form-group">
                    <input type="text" name="seek" placeholder="Search" class="form-control form-control-sm"
                           v-model="seek"
                           @keypress.enter="search">
                  </div>
                </div>
              </div>

              <div class="table-responsive">
                <table class="table table-bordered table-md">
                  <tbody>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Status</th>
                    <th class="text-center">Action</th>
                  </tr>
                  <tr v-for="(Delay, index) in Delays.data" :key="index">
                    <td>{{ Delay.id }}</td>
                    <td>{{ Delay.name }}</td>
                    <td>
                      <div class="badge badge-success" @click="activeInactive(Delay.id, Delay.active)"
                           :class="{'badge-success': Delay.active === 1, 'badge-danger': Delay.active !== 1}">
                        <span v-if="Delay.active === 1">Active</span>
                        <span v-else>Inactive</span>
                      </div>
                    </td>
                    <td class="text-center">
                      <a href="#" class="btn btn-primary btn-sm" data-toggle="modal" data-target="#newDelay"
                         @click.prevent="set(index)">Detail</a>
                      <a href="#" class="btn btn-danger btn-sm ml-1" @click.prevent="destroy(Delay.id)">Remove</a>
                    </td>
                  </tr>

                  </tbody>
                </table>
              </div>
            </div>
            <div class="card-footer text-right">
              Showing {{ Delays.to }} from {{ Delays.total }} Records
              <nav class="d-inline-block">
                <ul class="pagination mb-0">
                  <li class="page-item" :class="{'disabled': Delays.current_page === 1}">
                    <a class="page-link" href="#" tabindex="-1"
                       @click.prevent="show(Delays.current_page -1)">
                      <i class="fas fa-chevron-left"></i>
                    </a>
                  </li>

                  <li class="page-item" :class="{'disabled': Delays.current_page === Delays.last_page}">
                    <a class="page-link" href="#" @click.prevent="show(Delays.current_page + 1)"><i
                        class="fas fa-chevron-right"></i></a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>

    </div>
    <!-- Modal with form -->
    <div class="modal fade" id="newDelay" tabindex="-1" role="dialog" aria-labelledby="formModal"
         aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="formModal">New Delay Type</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click.p="resetForm">
              <i class="fa fa fa-window-close"></i>
            </button>
          </div>

          <div class="modal-body">
            <form enctype="multipart/form-data" method="post" id="DelayForm" @submit.prevent="store">
              <div class="row">
                <div class="col-12 col-md-12">
                  <div class="form-group">
                    <label for="name"> Name </label>

                    <input type="text" class="form-control" placeholder="Delay Type" name="name" id="name"
                           v-model="Delay.name">
                    <input type="hidden" name="delay_type_id" v-model="Delay.id" v-if="Delay.id">
                    <span class="text text-danger" v-if="validationErrors.name">{{ validationErrors.name[0] }}</span>

                  </div>
                </div>

                <div class="col-12 col-md-12">
                  <div class="form-group">
                    <label for="remark">Remark</label>

                    <textarea class="form-control" name="remark" id="remark"
                              v-model="Delay.remark"></textarea>

                    <span class="text text-danger"
                          v-if="validationErrors.remark">{{ validationErrors.remark[0] }}</span>

                  </div>
                </div>
              </div>

              <button type="submit" class="btn btn-primary m-t-15 waves-effect ml-2 float-right">Save</button>
              <button type="reset" class="btn btn-danger m-t-15 waves-effect float-right"
                      data-dismiss="modal" aria-label="Close"
                      @click="resetForm" v-if="Delay.id"
              >
                Cancel
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- Vertically Center -->
  </div>


</template>
<script>
import {activeInActive, destroy, search, show, store, update} from "@/services/lookup/DelayType";
import $ from 'jquery'

import {useToast} from "vue-toastification";

export default {
  data() {
    return {
      validationErrors: [],
      toast: useToast(),
      Delays: [],
      Delay: {},
      seek: ''
    }
  },


  methods: {
    setError(error) {

      this.toast.clear();

      if (error.response.status === 422) {

        this.validationErrors = error.response.data.errors;

        this.toast.error(error.response.data.message);

      } else {

        this.toast.error('Something went wrong, please contact webmaster.')
      }
    },
    show(page = 1) {
      show(page).then(response => {

        this.Delays = response.data;
      }).catch(error => {
        this.setError(error);
      })
    },

    store() {

      this.toast.info('processing...');

      let form = document.getElementById('DelayForm');

      let data = new FormData(form);

      if (!this.Delay.id) {

        store(data).then(() => {

          $('#newDelay').hide('hide');

          this.toast.clear();

          this.toast.success('New Delay Type Added');

          this.validationErrors = [];

          this.Delay = {};

          this.show();


        }, error => {

          this.setError(error);

        });

      } else {

        update(data).then(() => {

          $('#newDelay').hide('hide');

          this.toast.clear();

          this.toast.success('Delay Type Updated');

          this.Delay = {};

          this.validationErrors = [];

          this.show(this.Delays.current_page);


        }, error => {
          this.setError(error);
        });
      }

    },

    activeInactive(id, active) {

      let data = {
        id: id,
        active: active
      }

      this.toast.info('processing...')

      activeInActive(data).then(() => {
        this.toast.clear();
        this.show(this.Delays.current_page)
      });

    },

    set(index) {
      this.Delay = this.Delays.data[index];
    },

    resetForm() {

      $('#newDelay').hide('hide')
      this.Delay = {};

    },

    destroy(id) {

      let data = {
        'id': id,
      };

      this.$swal({

        title: 'Are you sure?',

        text: "You won't be able to revert this!",

        type: 'warning',

        showCancelButton: true,

        confirmButtonColor: '#d33',

        cancelButtonColor: '#3085d6',

        confirmButtonText: 'Yes, delete it!'

      }).then((result) => {

        if (result.value) {


          destroy(data).then(() => {


            this.$swal(
                'Deleted!',

                'Record has been deleted.',

                'success'
            );
            this.show(this.Delays.current_page);

          });


        }

      });


    },

    search() {
      let data = {
        'seek': this.seek,
      };
      search(data).then(response => {


        this.Delays.data = null;

        this.Delays.data = response.data;

      });
    }
  },

  mounted() {
    this.show();

  }
}
</script>