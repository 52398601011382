<template>
  <div class="section mb-5">

    <head-slot>
      <title>Scoring And Performance | Look UP</title>
    </head-slot>

    <div class="section-header">
      <h1>Scoring and Performance</h1>
      <div class="section-header-breadcrumb">
        <div class="breadcrumb-item active">
          <a href="#">Scoring and Performance</a>
        </div>
        <div class="breadcrumb-item">Lookup</div>

      </div>
    </div>

    <div class="section-body">

      <div class="row">
        <div class="col-12 col-md-6 col-lg-6">


          <Entity/>
        </div>
        <div class="col-12 col-md-6 col-lg-6">
          <OfficialType/>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6 col-lg-6">
          <TournamentType/>
        </div>
        <div class="col-12 col-md-6 col-lg-6">
          <TeamType/>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6 col-lg-6">
          <BreakType/>
        </div>
        <div class="col-12 col-md-6 col-lg-6">
          <DelayType/>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6 col-lg-6">
          <GameFormat @showGameFormats="setGameFormats" />
        </div>
        <div class="col-12 col-md-6 col-lg-6">
          <PowerPlay :game_formats="gameFormats" />
        </div>
      </div>
    </div>


  </div>

</template>
<script>
import Entity from './Entity.vue'
import OfficialType from './OfficialType.vue'
import TournamentType from './TournamentType.vue'
import TeamType from './TeamType.vue'
import BreakType from "./BreakType.vue";
import DelayType from "./DelayType.vue";
import GameFormat from "./GameFormat.vue"
import PowerPlay from "./PowerPlay.vue";

export default {
  components: {
    Entity,
    OfficialType,
    TournamentType,
    TeamType,
    BreakType,
    DelayType,
    GameFormat,
    PowerPlay
  },
  data(){
    return{
      gameFormats:[],
    }
  },
  methods:{
    setGameFormats(data){


      this.gameFormats = data;
    }
  }
}
</script>
<style>

.btn-right {
  position: absolute;
  right: 23px;
}

.modal-backdrop {
  height: 0;
}

</style>